import React from "react"
import Layout from "@/components/layout"
import Footer from "@/components/footer"
import HeaderOne from "@/components/header-one"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import SliderOne from "@/components/email-sucess"

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Colégio Bertoni Med">
          <HeaderOne />
          <SliderOne />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default HomeOne
