import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper"
import { Col, Container, Row } from "react-bootstrap"
import SliderImage from '../images/sucess.png'

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderOne = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    autoplay: {
      delay: 5000,
    },
  };
  return (
    <section className="main-slider" style={{ background: '#F9F9F9' }}>
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>            
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                <h2 className="titleDepoimemtos MB_32 txt-center">MENSAGEM ENVIADA COM SUCESSO</h2>
                <div style={{ marginBottom: 32 }}><img className="img-size" src={SliderImage} alt='' style={{ height: 'auto' }} /></div>
                <a href='/' className={`common_btn`}>
                  <span>Voltar para home</span>
                </a>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderOne;